<template>
  <div class="q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['flushes'])">
      <single-answer-question
        title="label.flushes"
        :value="flushes"
        :options="$options.yesNoOptions"
        dysfunction="skin-redness"
        yes-no
        @input="onFieldChange('flushes', $event)"
      />
    </question-with-error-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="showMenopauseQuestion"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['hotFlushes'])"
      >
        <single-answer-question
          id="hot-flushes"
          title="label.hotFlushes"
          :value="hotFlushes"
          :options="$options.yesNoApplicableResponseOptions"
          dysfunction="skin-redness"
          @input="onFieldChange('hotFlushes', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="flushesDetailsVisible"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['flushesDuration'])"
      >
        <single-answer-question
          id="flushes-duration"
          title="label.flushesDuration"
          :value="flushesDuration"
          :options="$options.flushDurationOptions"
          dysfunction="skin-redness"
          @input="onFieldChange('flushesDuration', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="flushesDetailsVisible"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['flushLocations', 'otherFlushLocations'])"
      >
        <multi-picture-select
          id="flush-locations"
          title="label.flushLocations"
          :gender="gender"
          :value="flushLocations"
          :other-value="otherFlushLocations"
          :options="$options.flushLocationsOptions"
          dysfunction="skin-redness"
          @input="onFieldChange('flushLocations', $event)"
          @other-input="onFieldChange('otherFlushLocations', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>
  </div>
</template>

<script>
import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import MultiPictureSelect from '@/modules/questionnaire/new-design-components/questions/MultiPictureSelect';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { YES_NO_RESPONSE_OPTIONS } from '@/modules/questionnaire/constants/questions';
import { FLUSH_DURATION } from '@/modules/questionnaire/api/constants';
import { FLUSH_LOCATION_OPTIONS } from '@/modules/questionnaire/constants/steps/skinRedness.js';

export const FLUSH_DURATION_OPTIONS = [
  { value: FLUSH_DURATION.FEW_MONTHS, text: 'flushesDuration.fewMonths' },
  { value: FLUSH_DURATION.MANY_YEARS, text: 'flushesDuration.forManyMonth' }
];

export default {
  name: 'FlushingTemplate',
  components: {
    FadeInTransitionWrap,
    SingleAnswerQuestion,
    QuestionWithErrorWrap,
    MultiPictureSelect
  },
  mixins: [stepTemplateMixin],
  yesNoOptions: YES_NO_RESPONSE_OPTIONS,
  yesNoApplicableResponseOptions: [
    ...YES_NO_RESPONSE_OPTIONS,
    { value: 'notApplicable', text: 'skinRednessState.notApplicable' }
  ],
  flushDurationOptions: FLUSH_DURATION_OPTIONS,
  flushLocationsOptions: FLUSH_LOCATION_OPTIONS,
  props: {
    gender: {
      type: String,
      required: true
    },
    flushes: {
      type: String,
      default: ''
    },
    hotFlushes: {
      type: String,
      default: ''
    },
    flushesDuration: {
      type: String,
      default: ''
    },
    flushLocations: {
      type: Array,
      default: () => []
    },
    otherFlushLocations: {
      type: String,
      default: ''
    },
    showMenopauseQuestion: {
      type: Boolean,
      default: false
    },
    flushesDetailsVisible: {
      type: Boolean,
      default: false
    }
  }
};
</script>
