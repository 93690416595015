<template>
  <flushing-template
    :gender="gender"
    :flushes="flushes"
    :hot-flushes="hotFlushes"
    :flushes-duration="flushesDuration"
    :flush-locations="flushLocations"
    :other-flush-locations="otherFlushLocations"
    :show-menopause-question="showMenopauseQuestion"
    :flushes-details-visible="flushesDetailsVisible"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import FlushingTemplate from '@/modules/questionnaire/components/steps/photo-analysis/skin-redness/flushing/FlushingTemplate';

import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { no, yes, female } from '@/modules/questionnaire/api/helpers';

const { field, requiredField, requiredArrayField } = fieldBuilder;

const FIELDS_TO_RESET_ON_NO = ['hotFlushes', 'flushesDuration', 'flushLocations'];

const REQUIRED_FIELDS = ['flushes', 'hotFlushes', 'flushesDuration', 'otherFlushLocations'];

const FIELDS = [
  field('age'),
  field('gender'),
  requiredArrayField('flushLocations'),
  ...REQUIRED_FIELDS.map(requiredField)
];

export default {
  name: 'Flushing',
  components: {
    FlushingTemplate
  },
  mixins: [makeStep(FIELDS), resetStoreValues],
  computed: {
    showMenopauseQuestion() {
      return female(this.gender) && yes(this.flushes) && this.age >= 50;
    },
    flushesDetailsVisible() {
      return yes(this.flushes);
    }
  },
  watch: {
    flushes(newValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName(FIELDS_TO_RESET_ON_NO);

        this.showNextStep();
      }

      if (this.showMenopauseQuestion) {
        this.scrollTo('#hot-flushes');
        return;
      }

      this.scrollTo('#flushes-duration');
    },
    hotFlushes() {
      this.scrollTo('#flushes-duration');
    },
    flushesDuration() {
      this.scrollTo('#flush-locations');
    }
  },
  methods: {
    isFieldVisible(fieldName) {
      if (fieldName === 'hotFlushes') {
        return this.showMenopauseQuestion;
      }

      if (fieldName === 'flushesDuration') {
        return this.flushesDetailsVisible;
      }

      if (fieldName === 'flushLocations') {
        return this.flushesDetailsVisible;
      }

      if (fieldName === 'otherFlushLocations') {
        return this.flushLocations.includes('other') && this.flushesDetailsVisible;
      }

      return true;
    }
  }
};
</script>
